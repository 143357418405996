<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="returnDialogFn" :isShow.sync="publicDialog">
      <div slot="title" class="title">公共费用付款申请详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <el-form class="form_box" ref="applyForm" :model="applyForm">
          <el-descriptions style="width: 100%" direction="vertical" :column="5" border>
            <el-descriptions-item label="单据编号" prop="expenseNumber" ref="expenseNumber">
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ applyForm.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="申请人">
              <el-form-item prop="createName">
                {{ applyForm.createName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="申请日期">
              <el-form-item prop="taskDate">
                {{ applyForm.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="费用所属部门">
              <el-form-item prop="deptId">
                {{ applyForm.deptName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>
                申请单位
              </template>
              <el-form-item prop="companyType">
                {{ applyForm.companyType | dict(dictData.companyType) }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>
                开户行
              </template>
              <el-form-item prop="openingBank">
                {{ applyForm.openingBank }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>
                银行账号
              </template>
              <el-form-item prop="bankAccount">
                {{ applyForm.bankAccount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item :span="2">
              <template slot="label">
                <span style="color: red">*</span>
                收款单位
              </template>
              <el-form-item prop="collectionUnit">
                {{ applyForm.collectionUnit }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource"
              content-class-name="businessOutSource"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">公共付款申请费用</span>
            </el-descriptions-item>
            <el-descriptions-item label="付款原因" :span="5">
              <template slot="label">
                <span style="color: red">*</span>
                付款原因
              </template>
              <el-form-item prop="paymentCause" style="width: 100%">
                {{ applyForm.paymentCause }}
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <el-table :data="applyForm.detailList" border>
          <el-table-column
            prop="typeOne"
            :show-overflow-tooltip="false"
            label="费用科目一"
            width="220"
          >
            <template slot="header"> <span style="color: red">*</span> 费用科目一 </template>
            <template slot-scope="scope">
              <Dictionary :disabled="true" v-model="scope.row.typeOne" code="CBFYZC" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTwo"
            :show-overflow-tooltip="false"
            label="费用科目二"
            width="220"
          >
            <template slot-scope="scope">
              <Dictionary :disabled="true" v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeThree"
            :show-overflow-tooltip="false"
            label="费用科目三"
            width="220"
          >
            <template slot-scope="scope">
              <Dictionary
                :disabled="true"
                v-model="scope.row.typeThree"
                :code="scope.row.typeTwo"
              />
            </template>
          </el-table-column>

          <el-table-column prop="cost" label="金额 (元)" width="120">
            <template slot="header"> <span style="color: red">*</span>金额 (元) </template>
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            scoped-slot
            prop="invoiceStatus"
            :show-overflow-tooltip="false"
            :label="'事由附件详细说明\n（含参与人员）'"
            width="250"
          >
            <template slot="header">
              <span style="color: red">*</span>事由附件详细说明（含参与人员）
            </template>
            <template slot-scope="scope">
              <el-input
                readonly
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 999 }"
                v-model="scope.row.invoiceStatus"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="left" label="附件上传" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                style="margin-top: 5px"
              >
                <a
                  v-if="isShowType(n)"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  target="_blank"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
                <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-descriptions title="" :column="3" border>
          <el-descriptions-item label="合计" labelStyle="width:10%">
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:10%">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="returnDialogFn">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
  </div>
</template>

<script>
export default {
  name: 'PublicDetailDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
  },
  data() {
    return {
      img_index: 0, //附件图片预览下标
      dialogVisible: false, //图片预览
      loading: false,
      filepath: process.env.VUE_APP_FILEPATH,
      costDetail: {},
      dictData: {
        companyType: [],
      },
      imgList: [],
      applyForm: {},
    }
  },
  props: {
    publicDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.applyForm.detailList) {
        sum = this.applyForm.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      return sum
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  watch: {
    publicDialog: {
      // deep: true,
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.$emit('returnDialogFn')
        } else {
          this.publicPaymentFn(this.options.publicSpendingPaymentApplicationId)
        }
      },
    },
  },
  methods: {
    publicPaymentFn(id) {
      this.loading = true
      this.$api.publicPayment
        .selectDetailById(id)
        .then(res => {
          if (res.data) {
            this.applyForm = res.data
            this.loading = false
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (v.id === n.id || (v.fileName === n.fileName && v.filePath === n.filePath)) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    // 关闭详情弹框
    returnDialogFn() {
      this.$emit('returnDialogFn')
    },
  },
}
</script>

<style lang="scss" scoped>
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe !important;
  border: 1px solid #ddd !important;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
</style>
